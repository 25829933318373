/* eslint-disable object-property-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PaymentOptions from '../payment_options/payment_options';
import classes from './classes.module.scss';
import { PaymentSourcesListType } from '../payment_methods_list/payment_sources_prop_types';
import { paymentSourcesTheme } from '../config/const';
import PaymentMethodsList from '../payment_methods_list/payment_methods_list';
import {
  starSymbol, renderPaymentMethodsListTitle, renderdDetailsButton,
  getPurchaseURL, renderBoldPrice, renderToast, rendeExtraPriceLabel
} from './purchase_details_common_utils';
import UsePreviousValue from '../config/utils';

function PurchaseDetailsCommon({
  clientAvailableCredit, loadingOptions, paymentMethodListAnalyticsEvents,
  getCurrentLocalization, showPaymentSources, log,
  renderCreditCardProcessing, onPurchaseDetailsScreenOpened, renderPayPalProcessing,
  paymentProcessing, hideAffiliatePromoPopupAction, options, addCreditCard, loadingSources,
  paymentSources, hidePaymentDetails, theme, backArrowAction,
  forMobileBuyBtn, paymentMethodListActions, onMakeDefault, trackAddFundsScreenEvent,
  setInAppPaymentOptions, receivedMessageFromApp, setMessageToAppData, userMPIdentify, purchaseDetails, isPayg, renderCreditCardValidateProcessing,
  creditCardValidateError, trackPageVisit, loadPaymentOptionsOnClose
}) {
  const { t } = useTranslation();
  const [detailsOpened, setDetailsOpened] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const [needTrackAddFundsScreenEvent, setNeedTrackAddFundsScreenEvent] = useState(true);
  const { current } = paymentSources;
  const [currentState, setuCurrentState] = useState(current);
  const {
    bonus = 0, changingSource, clickSource, duration, price, purchaseType, url, expiresAt,
    diffPrice, isMinPurchase, requiredCredit, fees
  } = purchaseDetails;

  useEffect(() => {
    onPurchaseDetailsScreenOpened();
    return () => { if (loadPaymentOptionsOnClose) loadPaymentOptionsOnClose(); };
  }, []);

  useEffect(() => {
    if (current !== currentState) setuCurrentState(current);
    const prevCurrent = <UsePreviousValue value={ currentState } />;
    if (current && (prevCurrent.props.value === undefined && prevCurrent.props.value !== current)) {
      onPurchaseDetailsScreenOpened();
    }
  }, [current]);

  useEffect(() => {
    if (options && options.length && needTrackAddFundsScreenEvent && userMPIdentify
      && requiredCredit) {
      trackAddFundsScreenEvent(parseFloat(requiredCredit.toFixed(2)));
      setNeedTrackAddFundsScreenEvent(false);
    }
  }, [options, userMPIdentify, requiredCredit]);

  const renderPurchaseButtonSection = () => {
    const requiredCreditToFixed = (requiredCredit || 0).toFixed(2);
    const purchaseURL = getPurchaseURL({ url, requiredCredit: requiredCreditToFixed, purchaseType });
    const paymentOptionsProps = {
      purchaseURL, type: purchaseType, purchaseAmount: price,
      requiredCredit: requiredCreditToFixed, isMinPurchase, bonus,
      expiresAt, renderCreditCardProcessing, renderPayPalProcessing, paymentProcessing,
      hideAffiliatePromoPopupAction, getCurrentLocalization, options, loadingOptions,
      loading: loadingSources, addCreditCard, paymentSources, theme, changingSource,
      forMobileBuyBtn, onPurchaseDetailsScreenOpened, showPaymentSources, setInAppPaymentOptions,
      fees, isPayg, renderCreditCardValidateProcessing, creditCardValidateError
    };
    return <PaymentOptions { ...paymentOptionsProps } />;
  };

  const renderDetailsSection = () => {
    if (!detailsOpened) return <div />;
    return (
      <div className={ classes.detailsView }>
        <div className={ classes.divider } />
        <div className={ classes.detailsViewText }>
          { `${ t('chat.required_funds', { mins: parseFloat(duration) / 60 }) } $${ price.toFixed(2) }` }
        </div>
        <div className={ classes.detailsViewText }>
          { `${ t('chat.current_balance') } $${ parseFloat(clientAvailableCredit).toFixed(2) }`}
        </div>
        <div className={ classes.divider } />
        <div className={ classes.detailsViewText }>
          <b>{ `${ t('chat.additional_funds_required') } $${ diffPrice.toFixed(2) }` }</b>
          { starSymbol(12, diffPrice) }
        </div>
      </div>
    );
  };

  const updateDefault = (source) => {
    const makeDefParams = {
      id:source.id, kind:source.kind, type:source.context.type, clickSource
    };
    onMakeDefault(history, location, makeDefParams);
    setShowToast(true);
    setTimeout(() => { setShowToast(false); }, 3000);
  };

  const renderPaymentMethodsList = () => {
    const paymentMethodsListProps = {
      ...paymentMethodListActions, analyticsEvents: paymentMethodListAnalyticsEvents,
      paymentSources, loading: loadingSources, theme: paymentSourcesTheme.purchase,
      log, updateDefault, changingSource, clickSource, onMakeDefault, setInAppPaymentOptions,
      receivedMessageFromApp, setMessageToAppData, isPayg, trackPageVisit
    };
    return <PaymentMethodsList { ...paymentMethodsListProps } />;
  };

  const renderPurchaseDetailsCommon = () => (
    <div className={ classes.container }>
      <div className={ classes.containerWithIcon }>
        <div className={ classes.dialogContainer }>
          { renderPaymentMethodsListTitle({
            purchaseType, backArrowAction, hidePaymentDetails, theme, diffPrice, t, bonus,
            receivedMessageFromApp, setMessageToAppData, isPayg, creditCardValidateError, loading: loadingSources
          }) }
          <div className={ classes.priceHolder }>
            <div className={ classes.flexCenteredDiv }>
              { renderBoldPrice({
                purchaseType, diffPrice, theme, isPayg
              }) }
            </div>
            <div className={ classes.flexCenteredDiv }>
              {rendeExtraPriceLabel({
                options, price, bonus, t, purchaseType
              })}
            </div>
            <div className={ classes.flexCenteredDiv }>
              { renderdDetailsButton({
                purchaseType, setDetailsOpened, detailsOpened, t, isPayg
              }) }
            </div>
            { renderDetailsSection() }
          </div>
          { renderPurchaseButtonSection() }
        </div>
        {renderToast({
          fees, showToast, isPayg, t
        })}
      </div>
    </div>
  );

  const renderPurchaseDetails = () => {
    if (changingSource) return renderPaymentMethodsList();
    return renderPurchaseDetailsCommon();
  };

  return renderPurchaseDetails();
}

PurchaseDetailsCommon.propTypes = {
  clientAvailableCredit: PropTypes.string,
  hidePaymentDetails: PropTypes.func,
  renderCreditCardProcessing: PropTypes.func,
  renderPayPalProcessing: PropTypes.func,
  paymentProcessing: PropTypes.func,
  hideAffiliatePromoPopupAction: PropTypes.func,
  getCurrentLocalization: PropTypes.func.isRequired,
  loadingOptions: PropTypes.bool.isRequired,
  options: PropTypes.array,
  addCreditCard: PropTypes.func,
  paymentSources: PropTypes.shape(PaymentSourcesListType),
  loadingSources: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf([paymentSourcesTheme.purchase, paymentSourcesTheme.wallet]),
  backArrowAction: PropTypes.func,
  forMobileBuyBtn: PropTypes.func,
  showPaymentSources: PropTypes.func.isRequired,
  onPurchaseDetailsScreenOpened: PropTypes.func,
  paymentMethodListActions: PropTypes.object.isRequired,
  paymentMethodListAnalyticsEvents: PropTypes.object.isRequired,
  log: PropTypes.func.isRequired,
  onMakeDefault: PropTypes.func,
  trackAddFundsScreenEvent: PropTypes.func.isRequired,
  setInAppPaymentOptions: PropTypes.func,
  receivedMessageFromApp: PropTypes.object,
  setMessageToAppData: PropTypes.func,
  userMPIdentify: PropTypes.bool.isRequired,
  purchaseDetails: PropTypes.object.isRequired,
  trackPageVisit: PropTypes.func,
  isPayg: PropTypes.bool,
  renderCreditCardValidateProcessing: PropTypes.func,
  creditCardValidateError: PropTypes.object,
  loadPaymentOptionsOnClose: PropTypes.func
};

PurchaseDetailsCommon.defaultProps = {
  clientAvailableCredit: '0',
  renderCreditCardProcessing: null,
  renderPayPalProcessing: null,
  paymentProcessing: null,
  hideAffiliatePromoPopupAction: null,
  options: [],
  addCreditCard: null,
  paymentSources: null,
  theme: paymentSourcesTheme.purchase,
  backArrowAction: null,
  forMobileBuyBtn: null,
  onPurchaseDetailsScreenOpened: null,
  hidePaymentDetails: null,
  onMakeDefault: null,
  setInAppPaymentOptions: null,
  receivedMessageFromApp: {},
  setMessageToAppData: null,
  trackPageVisit: null,
  isPayg: false,
  renderCreditCardValidateProcessing: null,
  creditCardValidateError: null,
  loadPaymentOptionsOnClose: null
};

export default PurchaseDetailsCommon;
